@import "./variable.scss";

@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin list-loop($className, $styleName, $offset: 4, $start: 0, $max: 72, $divider: 1) {
    $i: $start;
    @while $i <= $max {
        #{$className + $i} {
            #{$styleName}: #{$i / $divider + "px !important"};
        }
        $i: $i + $offset;
    }
}
