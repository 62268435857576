$breakpoints: (
    "mobile": (
        max-width: 576px,
    ),
    "tablet": (
        max-width: 768px,
    ),
) !default;

$BORDER_COLOR: #e8e8e8;
$PRIMARY_COLOR: #1890ff;
$LIGHT_TEXT_COLOR: #bfbfbf;
$PRIMARY_TEXT_COLOR: #262626;
$SECONDARY_TEXT_COLOR: #595959;

$MAX_WIDTH: 148rem;
