@import "./mixins.scss";
@import "./variable.scss";
@import "./helpers.scss";

:root.dark-theme {
  transition: all 0.5s;
  filter: invert(0.9) hue-rotate(180deg);
}

:root.dark-theme img {
  filter: invert(0.9) hue-rotate(180deg) brightness(120%);
}

#root {
  height: 100%;
}

html {
  font-size: 62.5%;
  font-family: "Ubuntu", sans-serif;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

body {
  font-weight: 400;
  font-size: 1.6rem;
  color: $PRIMARY_TEXT_COLOR;
  background-color: #fafbfb;
  font-family: "Ubuntu", sans-serif;
}

.feature-container {
  min-height: 100%;
  max-width: $MAX_WIDTH;
}

.ant-card.stats-card {
  border-radius: 6px;
  .ant-card-body {
    padding: 1.6rem;
  }
}

.app-loader {
  margin: 3.6rem 0;
  text-align: center;
}

// Pages CSS

.privacy-policy,
.terms-services {
  padding: 2rem 0;
}

.register-modal {
  .ant-modal-body {
    padding: 0;
    .features-col {
      background-color: #fafafa;
      > div {
        padding: 2.8rem;
      }
    }
    .form-col {
      > div {
        padding: 2.8rem;
      }
    }
  }
}

.login-modal {
  .ant-modal-body {
    padding: 3.6rem;
  }
}

.tax-selection-form-item {
  .ant-divider {
    margin: 1rem 0;
  }
  .ant-space {
    display: flex;
    padding: 0 1rem 0.8rem 1rem;
    .ant-space-item:first-child {
      flex: 1;
    }
  }
}

.color-selection-form-item {
  width: 100%;
  display: flex;
  margin: 1rem 0 2rem 0;
  // justify-content: space-between;

  .ant-space-item {
    position: relative;
  }

  .preselect-color-item {
    padding: 1.6rem;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    &.selected {
      padding: 0.5rem 1rem;
    }
    .anticon {
      color: #ffffff;
      font-size: 1.3rem;
    }
  }

  .color-picker-icon {
    padding: 0.6rem;
    cursor: pointer;
    font-size: 1.8rem;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    background-color: #fafafa;
  }

  .ant-input[type="color"] {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
}

.preselect-templates {
  width: 24rem;
  overflow-x: auto;
  white-space: nowrap;
  .preselect-template-item {
    cursor: pointer;
    margin-right: 1rem;
    position: relative;
    display: inline-block;
    border: 1px solid $BORDER_COLOR;
    img {
      width: 13rem;
      height: auto;
      object-fit: cover;
    }
    .anticon {
      top: 1rem;
      right: 1rem;
      color: #1890ff;
      font-size: 1.8rem;
      position: absolute;
    }
  }
}

.receipt-logo-upload-form-item {
  .ant-input {
    border: 0;
  }
  .receipt-logo-preview {
    padding: 1rem;
    text-align: center;
    margin-bottom: 2rem;
    border: 1px dashed $BORDER_COLOR;
    img {
      max-width: 25rem;
    }
  }
  .ant-space {
    width: 100%;
    display: flex;
    .ant-space-item {
      flex: 1;
      .ant-btn {
        width: 100%;
      }
    }
  }
  .empty-area {
    display: flex;
    cursor: pointer;
    padding: 3rem 1rem;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    background-color: #f5f5f5;
    border: 1px dashed #d9d9d9;
  }
  .ant-upload.ant-upload-select {
    width: 100%;
  }
}

.receipt-generator {
  .ant-col {
    .ant-card {
      &:not(:last-child) {
        border-bottom: 0;
      }
      &:not(:first-child) {
        border-top: 0;
      }
    }
  }
  .total-card {
    border-top: 1px solid $BORDER_COLOR !important;
    .ant-space {
      align-items: center;
      margin-bottom: 1.2rem;
      .ant-space-item {
        flex: auto;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .item-delete-icon {
    padding: 1.2rem;
    font-size: 1.8rem;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
      background-color: #fafafa;
    }
  }

  .template-preview {
    padding: 3rem;
    border-radius: 8px;
    background: #f5f5f5;
    border: 1px solid $BORDER_COLOR;
    iframe {
      border: 0;
      width: 100%;
      height: 107rem;
    }
  }
  .ant-card {
    .ant-card-head-title h3 {
      font-size: 1.6rem;
    }
    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      .ant-card-body {
        padding-bottom: 0.8rem;
      }
    }
  }
}

.website-home {
  .receipt-generator {
    margin-bottom: 6rem;
  }
  .home-section {
    padding: 8rem 0;
    .feature-container {
      gap: 1.6rem;
      display: flex;
      max-width: 92rem;
      align-items: center;
      > div {
        flex: 1;
      }
      svg {
        width: 36rem;
      }
    }
    ul {
      margin: 0;
      padding: 0 0 0 1.6rem;
    }
    &.light {
      background-color: #ffffff;
    }
  }
}

.website-feature-section {
  background-color: #002766;
  h1 {
    color: #ffffff;
  }
  img {
    margin: 6rem 0 0;
  }
  .ant-col:first-child {
    color: #ffffff;
    padding: 6rem 4.8rem 6rem 0;
  }
}

.website-pricing {
  padding: 6rem 0;
}

.ant-modal.plan-upgrade-modal {
  bottom: 0;
  height: 100%;
  top: 0;
  max-width: 100%;
  padding-bottom: 0;
  width: 100% !important;
  .ant-modal-content {
    height: 100%;
    .ant-modal-header {
      border-bottom: 1px solid #d9d9d9;
      .ant-modal-title {
        font-weight: 500;
      }
    }
    .ant-modal-body {
      padding: 6rem;
      overflow-y: auto;
      height: calc(100% - 5.6rem);
      background-color: #fafafa;
    }
  }
}

.app-plans {
  display: flex;
  align-items: center;
  margin: 2.8rem 0 0 0;
  flex-direction: column;
  .plan-item-card {
    width: 40rem;
    min-height: 66rem;
    &.recommended {
      color: #ffffff;
      background-color: #002766;
    }
    .feature-item {
      display: flex;
      margin-bottom: 0.8rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// ANTD

.ant-avatar {
  &.contain img {
    object-fit: contain;
  }
}

.ant-card.no-padding {
  > .ant-card-body {
    padding: 0;
  }
}

.ant-modal.no-padding {
  .ant-modal-body {
    padding: 0;
  }
}

.ant-card-bordered {
  border: 1px solid $BORDER_COLOR;
}

.ant-form-item.no-margin {
  margin-bottom: 0;
}

.ant-table {
  border: 1px solid $BORDER_COLOR;
  border-bottom: 0;
  .ant-table-thead > tr > th {
    &:before {
      display: none;
    }
  }
}

.ant-page-header {
  padding: 0 0 2rem 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  // Website
  .website-layout {
    .ant-layout-content {
      .website-feature-section {
        .feature-container {
          .ant-col:last-child {
            display: none;
          }
        }
      }
    }
  }

  // Plans
  .app-plans {
    .plans-row {
      > .ant-space-item {
        .plan-item-card {
          width: 32rem;
        }
      }
    }
  }

  // Receipt settings
  .receipt-settings .template-preview {
    padding: 0;
  }

  // Receipt generator
  .app-layout {
    .receipt-generator {
      .header {
        padding: 0;
        display: block;
        > .ant-space-item {
          flex: 1;
        }
        .actions {
          flex-wrap: wrap;
          margin-top: 1.2rem;
        }
      }
      .preview {
        .right-settings {
          display: none;
        }
        .left-settings {
          display: block;
        }
      }
      .settings-form:not(.preview) {
        display: block;
        .right-settings {
          display: none;
        }
      }
      .receipt-form:not(.preview) {
        display: block;
        .left-settings {
          display: none;
        }
        .right-settings {
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  // Antd card
  .ant-card {
    .ant-card-head {
      padding: 0 1.6rem;
    }
    .ant-card-body {
      padding: 1.6rem;
    }
  }

  // Antd modal
  .ant-modal {
    top: 0;
    margin: 0;
    height: 100%;
    max-width: 100%;
    padding-bottom: 0;
    .ant-modal-header {
      padding: 1.6rem;
    }
    .ant-modal-content {
      height: 100%;
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      justify-content: space-between;
      .ant-modal-body {
        flex: 1;
        padding: 1.6rem;
      }
    }
  }

  // Plan upgrade
  .plan-upgrade-modal {
    .ant-modal-content .ant-modal-body {
      padding: 3.2rem 1.6rem;
    }
  }

  // Register modal
  .register-modal .ant-modal-content .ant-modal-body {
    padding-bottom: 0;
    > .ant-row {
      display: block;
    }
    .form-col > div {
      padding: 0;
    }
    .features-col {
      width: calc(100% + 3.2rem);
      max-width: calc(100% + 3.2rem);
      margin: 2.4rem 0 0 -1.6rem;
      > div {
        padding: 1.6rem;
      }
      .text-4xl {
        line-height: 1.3;
      }
    }
  }

  // Plans
  .app-plans {
    .plans-row {
      width: 100%;
      flex-direction: column;
      > .ant-space-item {
        width: 100%;
        .plan-item-card {
          width: auto;
        }
      }
    }
  }

  // Receipt generator
  .receipt-generator {
    .header {
      display: block;
      padding: 1.6rem 1.6rem 0 1.6rem;
      > .ant-space-item {
        flex: 1;
      }
      .actions {
        flex-wrap: wrap;
        margin-top: 1.2rem;
      }
    }
    .preview {
      .right-settings {
        display: none;
      }
      .left-settings {
        display: block;
      }
    }
    .settings-form:not(.preview) {
      display: block;
      .right-settings {
        display: none;
      }
    }
    .receipt-form:not(.preview) {
      display: block;
      .left-settings {
        display: none;
      }
      .right-settings {
        display: block;
      }
    }
    .ant-form {
      .ant-card {
        border-left: 0;
        border-right: 0;
      }
    }
    .template-preview {
      padding: 0;
    }
    .ant-card-body {
      .item-row {
        position: relative;
        padding-bottom: 1.6rem;
        border-bottom: 1px solid #d9d9d9;
        .item-col {
          &:first-child {
            flex: 100% !important;
            margin-bottom: 1.6rem;
          }
          &:not(:first-child):not(:last-child) {
            flex: 1 !important;
          }
          &:last-child {
            position: absolute;
            right: 0;
            bottom: 2rem;
            .item-delete-icon {
              padding: 0.6rem;
            }
          }
        }
        &:last-of-type {
          border: 0;
        }
      }
    }
  }

  // Website
  .website-layout {
    .website-home {
      > .feature-container {
        padding: 0 !important;
        margin: 0 !important;
      }
      .home-section {
        padding: 2.4rem 0;
      }
    }
  }

  // App layout
  .app-layout {
    .receipt-generator {
      margin: -1.6rem;
    }
    .receipt-settings {
      .ant-row {
        display: block;
        .preview-col {
          display: none;
        }
        .form-col {
          flex: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .website-home {
    .home-section {
      .feature-container {
        max-width: 92rem;
        flex-direction: column;
        > div {
          order: 1;
        }
        svg {
          order: 0;
          width: 32rem;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}
